
<template>
    <div>
    <b-card>
      <b-row class="">
        <b-col class="col-md-12">
          <h2 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1">
            {{ $t("debtor_ips") }}
          </h2>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
        <Table
        :items="ips"
        striped
        :url="url"
        responsive
        hover
        class="position-relative mt-2"
        show-empty
        :fields="fields"
      >
        <template #head()="scope">
          <div class="text-nowrap">{{ $t(scope.label) }}</div>
        </template>
        <template #cell(confirmation_date)="data">
          <p style="width:80px !important;">{{ data.value.substring(10,0) }}</p>
        </template>
        <template #cell(company_name)="data">
         <span v-if="data.item.company && data.item.company.name !=null">{{data.item.company.name}}</span>
         <span v-else>{{data.item.company.user[0].name}} {{data.item.company.user[0].last_name}}</span>
        </template>
        <template #cell(debtor_name)="data">
          <span v-if="data.item.debtor && data.item.debtor.name">
            {{ $t(data.item.debtor.name) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(id)="data">
        <p style="width:55px;" class="badge badge-light-primary">
           I-{{ data.value }}
        </p>
      </template>
        </Table>
    </b-card>
</div>
  </template>
  
  <script>
  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
    },
    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        page: 1,
        totalItems: 0,
        url: '/invoice/admin/debtors/ips',
        ips: null,
        fields: [
        {
          key: "confirmation_ip_address",
          label: "ip",
        },
        {
          key: "confirmation_date",
          label: "date",
        },
        {
          key: "id",
          label: "invoice_id",
        },
        {
          key: "debtor_name",
          label: "name",
        },
        
      ],
      };
    },
    created() {
        this.getDebtorIPs();
    },
    methods: {
  getDebtorIPs(){
    this.$http
        .post(`/invoice/admin/debtors/ips?page=` + this.page)
        .then((res) => {
          if (res) {
            this.ips = res.data.value.data;
            this.totalItems = res.data.value.total;
            this.page = res.data.value.current_page;
          }
        });
  },
    },
  };
  </script>
  
  <style scoped>
  </style>
  